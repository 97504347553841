.product

    &-details,
    &-list
        .product-image
            object-fit: cover

    &-details
        .product-image
            height: 222px

    &-list
        .product-image
            height: 312px
