.Toastify
    &__toast
        margin-bottom: 0

        &--error
            background-color: #cd201f
            border-color: #cd201f

        &--info
            background-color: #219af0
            border-color: #219af0

        &--success
            background-color: #5eba00
            border-color: #5eba00

        &--warning
            background-color: #cea70c
            border-color: #cea70c
