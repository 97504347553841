.fc-view

    .fc
        &-daygrid-dot-event:hover,
        &-event
            background: #3788d8
            color: #fff

        &-event
            overflow: hidden
