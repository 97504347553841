.avatar

    &-dropdown
        i
            position: absolute
            right: .5rem
            bottom: 1rem
            z-index: 1
            background: #e4e5ea
            padding: 3px
            border: 1px solid $white
            border-radius: 50%
            font-size: .75rem
