.react-select

    &-note
        color: hsl(0, 0%, 40%)
        display: inline-block
        font-size: 12px
        font-style: italic
        margin-top: 1em
        margin-right: .5rem
        cursor: pointer
