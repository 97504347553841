.dropdown

    &.user-events-dropdown
        .dropdown-menu
            width: 360px

            .dropdown-item
                white-space: normal

                .avatar
                    float: left
