.icon-success
  color: #5eba00 !important

.dropdown-header-icon-lg
  > a
    .fe
      font-size: 1.3em

.header-icon-lg
  i.fe
    font-size: 1.3em

.icon-calandar-clear
  right: 10px
