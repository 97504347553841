.card-body-click
  cursor: pointer

.card-header
  &.camp-image
    height: 18rem
    background-size: cover
    background-position-x: center
    background-position-y: center
    background-repeat: no-repeat

.card-header
  &.team-image
    height: 10rem
    background-size: cover
    background-position-x: center
    background-position-y: center
    background-repeat: no-repeat

.card-profile .card-header
  background-size: cover
  background-position-x: center
  background-position-y: center
  background-repeat: no-repeat

.card-profile .card-body
  min-height: 120px

.card-profile
  &.team-image .card-header
    background-size: contain

.card-favicon .card-header
    background-size: contain
    background-position-x: left
    background-position-y: center
    background-repeat: no-repeat
    height: 20px

.card-logo-header .card-header
    background-size: contain
    background-position-x: left
    background-position-y: center
    background-repeat: no-repeat
    height: 40px

.group-card .image-header
  background-size: cover
  background-position-x: center
  background-position-y: center
  background-repeat: no-repeat

.group-card .opacity
  background-color: #42413Cad !important

.invoice-image
  height: 6rem
