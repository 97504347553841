// Full width on small sizes
.grid-col
    &.col-xs-12
        @media (max-width: 575px)
            flex: 0 0 100%
            max-width: 100%

            &:not(:last-child)
                margin-bottom: 1rem

    &.col-sm-6
         @media (max-width: 575px)
            flex: 0 0 100%
            max-width: 50%
