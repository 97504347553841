.switchradio
    overflow: hidden

    &-input
        clip: rect(0, 0, 0, 0)
        bottom: 0

        &:checked

            &+label
                background: $primary
                border: none
                color: #fff

    &-label
        transition: all 0.1s ease-in-out
        background: $white
        max-width: 202px

        &:hover
            color: $primary
            border-color: $primary

        &:not(:last-child)
            margin-right: 1rem
