.timer-container
	min-width: 7em
	min-height: 7em

	time.cal-icon
		font-size: 1em
		display: block
		width: 7em
		height: 7em
		background-color: #fff
		margin: 0 auto
		border-radius: 0.6em
		overflow: hidden
		-webkit-backface-visibility: hidden
		-webkit-transform: rotate(0deg) skewY(0deg)
		-webkit-transform-origin: 50% 10%
		transform-origin: 50% 10%
		position: absolute
		bottom: 0
		left: 0
		right: 0

		*
			display: block
			width: 100%
			font-size: 1em
			font-weight: bold
			font-style: normal
			text-align: center

		strong
			position: absolute
			top: 0
			padding: 0.4em 0
			color: #fff
			background-color: #fd9f1b
			box-shadow: 0 2px 0 #fd9f1b
			height: 30px

		span
			width: 100%
			font-size: 2.8em
			letter-spacing: -0.05em
			padding-top: 0.8em
			color: #2f2f2f
