.search-container
    position: relative

    &-dropdown
        position: absolute
        width: 100%
        z-index: 2
        background-color: hsl(0,0%,100%)
        border-radius: 4px
        box-shadow: 0 0 0 1px hsla(0,0%,0%,0.1), 0 4px 11px hsla(0,0%,0%,0.1)
        margin: .5rem 0
        padding: .25rem 0

        &-item
            padding: 0.375rem 0.75rem
            transition: all 0.2s
            align-items: center

            &-highlighted
                background-color: #DEEBFF
                padding-left: 1rem
